<div class="spinner container-fluid" [class.spinner-hidden]="!showSpinner">
    <div class="row h-100 w-100 justify-content-center mx-auto">
        <div class="spinner-content col-1 p-3">
            <i class="fa fa-spinner fa-spin fa-5x fa-fw"></i>
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>

<div class="spinner-background" [class.spinner-hidden]="!showSpinner"></div>
