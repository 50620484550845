import { Injectable, Inject, forwardRef, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { catchError, map } from 'rxjs/operators';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { IdentityService } from '../services/identity.service';
import { SpinnerService } from '../services/spinner.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
	identityService: IdentityService;
	refreshTokenInProgress = false;

	tokenRefreshedSource = new Subject<void>();
	tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

	constructor(@Inject(forwardRef(() => OAuthStorage)) private authStorage: OAuthStorage, private injector: Injector, private spinnerService: SpinnerService) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
	{
		// Clone the request and set the Authorization header
		const authReq = this.getAuthHeader(req);

		// Handle the request and catch any errors
		return next.handle(authReq).pipe(
			map(results =>
			{
				if (results.type !== 0)
				{
					const body = results['body'];
					const url: string = results['url'];

					// Check if the request is a batch request and if there are any errors in the responses
					if (body && url?.indexOf('$batch') > -1)
					{
						// Check if the body is a string or an object
						const responses = typeof body === 'string' ? JSON.parse(body)?.responses : body['responses'];

						// Filter the responses to get the ones with errors
						const responsesWithErrors = responses?.filter(r => r.status >= 400);

						if (responsesWithErrors?.length > 0)
						{
							// If there are responses with errors, throw the first error
							throw responsesWithErrors[0] as HttpErrorResponse;
						}
					}

					const ssTryRelogin = sessionStorage.getItem('try_relogin');

					if (!ssTryRelogin)
					{
						// Used to keep track of the last action by a user in Design Tool in case the call fails.
						// Value is not needed if the request was successful
						sessionStorage.removeItem('last_action');
					}
				}

				return results;

			}),
			catchError((error: HttpErrorResponse) =>
			{
				// Handle response errors
				return this.handleResponseErrors(error, req, next);
			})
		);
	}
		
	getAuthHeader(request)
	{
		// Clone the request and set the Authorization header
		return request.clone({
			headers: request.headers.set('Authorization', 'Bearer ' + this.authStorage.getItem('id_token'))
		});
	}

	handleResponseErrors(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler)
	{
		// Get the instance of IdentityService
		this.identityService = this.injector.get(IdentityService);

		if (this.identityService.enableAutoRelogin)
		{
			// If the error status is 400 or 401 and the error URL is the token endpoint
			if (((error.status === 400 || error.status === 401) && error.url === this.identityService.tokenEndpoint) || error.status === 401)
			{
				// Log the user in and return an Observable of EMPTY
				this.identityService.relogin = true;

				// need to disable the spinner if any else it will block any messaging to the user
				this.disableSpinner();

				return EMPTY;
			}

			// If the error status is 401 and the error URL is not the token endpoint, attempt to refresh the token
			//if (error.status === 401)
			//{
			//	return this.refreshTokenAndRetry(request, next);
			//}
		}

		// If the error status is not 400 or 401, or the error URL is not the token endpoint, throw the error
		return throwError(error);
	}

	/**
	 * The code below tries to log the user back in, if it works then it retries the call.
	 * Right now this isn't working with forkjoin or calls with extra code after that relies on the return
	 * but for now would like to keep this around in case if it's needed in the future.
	 */
	//refreshTokenAndRetry(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
	//{
	//	return this.refreshToken()
	//		.pipe(
	//			switchMap(() =>
	//			{
	//				// If the token refresh is successful, re-send the request with the refreshed token
	//				return next.handle(this.getAuthHeader(request));
	//			}),
	//			catchError((error: HttpErrorResponse) =>
	//			{
	//				// If the token refresh fails and the error URL is the token endpoint
	//				if (error.url === this.identityService.tokenEndpoint)
	//				{
	//					// Log the user in and return an Observable of EMPTY
	//					this.identityService.relogin = true;

	//					return EMPTY;
	//				}

	//				// If the token refresh fails and the error URL is not the token endpoint, throw the error
	//				return throwError(error);
	//			})
	//		);
	//}

	//refreshToken(): Observable<any>
	//{
	//	// Check if a token refresh operation is already in progress
	//	if (this.refreshTokenInProgress)
	//	{
	//		// If a refresh is in progress, return a new Observable that completes when the current refresh operation is completed
	//		return new Observable(observer =>
	//		{
	//			// need to disable the spinner if any else it will block any messaging to the user
	//			this.disableSpinner();

	//			this.tokenRefreshed$.subscribe(() =>
	//			{
	//				observer.next();
	//				observer.complete();
	//			});
	//		});
	//	}
	//	else
	//	{
	//		// If a refresh is not in progress, start a new refresh operation
	//		this.refreshTokenInProgress = true;

	//		// need to disable the spinner if any else it will block any messaging to the user
	//		this.disableSpinner();

	//		return this.identityService.refreshUser().pipe(
	//			tap(() =>
	//			{
	//				// After the refresh operation is completed, reset the refreshTokenInProgress flag
	//				this.refreshTokenInProgress = false;

	//				// Emit a value from tokenRefreshedSource
	//				this.tokenRefreshedSource.next();
	//			}),
	//			catchError(error =>
	//			{
	//				// If an error occurs during the refresh operation, reset the refreshTokenInProgress flag
	//				this.refreshTokenInProgress = false;

	//				// Re-throw the error
	//				return throwError(error);
	//			})
	//		);
	//	}
	//}

	disableSpinner()
	{
		this.spinnerService.showSpinner(false);
	}
}
