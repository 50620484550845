import { PriceBreakdown } from './scenario.model';

import { DesignToolAttribute } from './attribute.model';
import { PlanOption } from './option.model';
import { Choice, DecisionPoint, SubGroup, Group, ChoiceImageAssoc } from './tree.model';

export interface SummaryData
{
	title: string;
	images: SDImage[];
	hasHomesite: boolean;
	buyerInfo: BuyerInfo;
	priceBreakdown: PriceBreakdown;
	priceBreakdownTypes: string[];
	includeImages: boolean;
	allowEstimates: boolean;
	groups: SDGroup[];
	nonStandardOptions: SDNonStandardOptions[];
}

export interface BuyerInfo
{
	communityName: string;
	planName: string;
	homesite: string;
	address: string;
}

export enum SummaryReportType
{
	SELECTIONS = 'Selections',
	SELECTIONS_IMAGES = 'Selections w/Images',
	OPTION_DETAILS = 'Option Details',
	OPTION_DETAILS_IMAGES = 'Option Details w/Images',
	FLOOR_PLAN = 'Floor Plan',
	CHOICE_LIST = 'Choice List',
	DESIGN_CHOICE_LIST = 'Design Choice List',
	SALES_CHOICE_LIST = 'Sales Choice List'
}

export class SDGroup
{
	id: number;
	label: string;
	subGroups: Array<SDSubGroup>;

	constructor(g: Group)
	{
		this.id = g.id;
		this.label = g.label;
		this.subGroups = [];
	}
}

export class SDSubGroup
{
	id: number;
	label: string;
	useInteractiveFloorplan: boolean;
	points: Array<SDPoint>;

	constructor(sg: SubGroup)
	{
		this.id = sg.id;
		this.label = sg.label;
		this.useInteractiveFloorplan = sg.useInteractiveFloorplan;
		this.points = [];
	}
}

export class SDPoint
{
	id: number;
	label: string;
	choices: Array<SDChoice>;
	completed: boolean;
	status: string;
	price: number = 0;
	dPointTypeId: number;
	groupName: string;
	subGroupName: string;

	constructor(p: DecisionPoint)
	{
		this.id = p.id;
		this.label = p.label;
		this.completed = p.completed;
		this.status = p.status.toString();
		this.price = p.price || 0;
		this.choices = [];
		this.dPointTypeId = p.dPointTypeId;
		this.groupName = null;
		this.subGroupName = null;
	}
}

export class SDChoice
{
	id: number;
	divChoiceCatalogId?: number;
	label: string;
	quantity: number = 0;
	maxQuantity: number = 1;
	price: number = 0;
	selectedAttributes: Array<DesignToolAttribute> = [];
	options: PlanOption[] = [];
	hasChoiceRules: boolean;
	hasOptionRules: boolean;
	hasAttributes: boolean = false;
	hasLocations: boolean = false;
	isElevationChoice?: boolean = false;
	minPrice?: number;
	maxPrice?: number;
	description?: string;
	attributeReassignments: SDAttributeReassignment[] = [];
	priceHiddenFromBuyerView?: boolean = false;
	choiceImages?: SDChoiceImage[] = [];

	constructor(c: Choice, priceRange?: { choiceId: number, min: number, max: number })
	{
		this.id= c.id;
		this.divChoiceCatalogId = c.divChoiceCatalogId;
		this.hasChoiceRules= c.hasChoiceRules;
		this.hasOptionRules= c.hasOptionRules;
		this.label= c.label;
		this.quantity= c.quantity;
		this.maxQuantity= c.maxQuantity;
		this.price= c.price;
		this.selectedAttributes = c.selectedAttributes.map(x =>
		{
			let fromChoiceId = null;

			if (c.mappedAttributeGroups && c.mappedAttributeGroups.length > 0)
			{
				const group = c.mappedAttributeGroups.find(ag => ag.id === x.attributeGroupId);

				fromChoiceId = group ? group.attributeReassignmentFromChoiceId : null;
			}

			return <DesignToolAttribute>{
				attributeGroupId: x.attributeGroupId,
				attributeGroupLabel: x.attributeGroupLabel ? x.attributeGroupLabel : null,
				attributeGroupName: x.attributeGroupName ? x.attributeGroupName : null,
				attributeId: x.attributeId,
				attributeImageUrl: x.attributeImageUrl ? x.attributeImageUrl : null,
				attributeName: x.attributeName ? x.attributeName : null,
				manufacturer: x.manufacturer ? x.manufacturer : null,
				sku: x.sku ? x.sku : null,
				locationGroupId: x.locationGroupId ? x.locationGroupId : null,
				locationGroupLabel: x.locationGroupLabel ? x.locationGroupLabel : null,
				locationGroupName: x.locationGroupName ? x.locationGroupName : null,
				locationId: x.locationId ? x.locationId : null,
				locationName: x.locationName ? x.locationName : null,
				locationQuantity: x.locationQuantity ? x.locationQuantity : null,
				scenarioChoiceLocationId: x.scenarioChoiceLocationId ? x.scenarioChoiceLocationId : null,
				scenarioChoiceLocationAttributeId: x.scenarioChoiceLocationAttributeId ? x.scenarioChoiceLocationAttributeId : null,
				action: x.action ? x.action : null,
				attributeReassignmentFromChoiceId: fromChoiceId || null,
				isAttributeReassigned: x.isAttributeReassigned || false
			}
		});
		this.options= c.options;
		this.hasAttributes = c.mappedAttributeGroups && c.mappedAttributeGroups.length > 0;
		this.hasLocations = c.mappedLocationGroups && c.mappedLocationGroups.length > 0;
		this.description = c.description;
		this.minPrice = priceRange ? priceRange.min : null;
		this.maxPrice = priceRange ? priceRange.max : null;
		this.priceHiddenFromBuyerView = c.priceHiddenFromBuyerView;
		this.choiceImages = c.choiceImages ? c.choiceImages.map(c => new SDChoiceImage(c)) : [];
	}
}

export class SDAttributeReassignment
{
	id: number;
	label: string;
}

export class SDImage
{
	imageUrl: string;
	hasDataUri?: boolean = false;
	floorIndex?: number;
	floorName?: string;
}

export class SDChoiceImage
{
	dpChoiceId: number;
	imageUrl: string;
	sortKey: number;

	constructor(choiceImage?: ChoiceImageAssoc)
	{
		if (choiceImage)
		{
			this.dpChoiceId = choiceImage.dpChoiceId;
			this.imageUrl = choiceImage.imageUrl;
			this.sortKey = choiceImage.sortKey;
		}
	}
}

export class SDNonStandardOptions
{
	name: string;
	description: string;
	action: string;
	quantity: number;
	unitPrice: number;

	constructor(nso: { name: string, description: string, quantity: number, unitPrice: number, action?: string })
	{
		this.name = nso.name;
		this.description = nso.description;
		this.action = nso.action;
		this.quantity = nso.quantity;
		this.unitPrice = nso.unitPrice;
	}
}
