<div aria-label="Confirm Modal" role="dialog" aria-modal="true" aria-labelledby="confirm-title">
	<div class="modal-header">
		<h1 class="modal-title" id="confirm-title">{{title}}</h1>
		<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div [innerHTML]="body"></div>
	</div>
	<div class="modal-footer" *ngIf="!!defaultOption">
		<button *ngIf="!primaryButton.hide" type="button" class="btn btn-sm" (click)="activeModal.close('Continue')" [class.btn-secondary]="defaultOption != 'Continue'" [class.btn-primary]="defaultOption == 'Continue'">
			<span class="fa fa-fw fa-check" aria-hidden="true"></span>
			<span class="">{{primaryButton.text}}</span>
		</button>
		<button *ngIf="!secondaryButton.hide" type="button" class="btn btn-sm ml-auto" (click)="activeModal.close('Close')" [class.btn-secondary]="defaultOption != 'Cancel'" [class.btn-primary]="defaultOption == 'Cancel'">
			<span class="fa fab fa-fw fa-close fa-times" aria-hidden="true"></span>
			<span class="">{{secondaryButton.text}}</span>
		</button>
	</div>
</div>
