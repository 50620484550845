import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { SpinnerService } from '../../services/spinner.service';
import { UnsubscribeOnDestroy } from '../../utils/unsubscribe-on-destroy';

@Component({
	selector: 'common-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent extends UnsubscribeOnDestroy implements OnInit
{
	showSpinner: boolean = false;

	constructor(private spinnerService: SpinnerService, private changeDetectorRef: ChangeDetectorRef) { super(); }

	ngOnInit(): void
	{
		this.spinnerService.spinnerActive.subscribe(val =>
		{
			val ? this.show() : this.hide();
			this.changeDetectorRef.detectChanges();
		});
	}

	show(): void
	{
		this.showSpinner = true;
	}

	hide(): void
	{
		this.showSpinner = false;
	}
}
